<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4">
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">{{message}}</h2>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import {mapActions} from 'vuex'
  export default {
    data() {
      return {
        message: ''
      }
    },
    methods:{
      ...mapActions('auth',['verifyEmail'])
    },
    mounted(){
      const payload = {token:this.$route.params.token}
      this.verifyEmail(payload).then(res=>{
        this.message = res.data.message
      }).catch(err=>{
        if(err){
          this.message = err.data.message
        }
        else{
          this.message = 'Unable to verify at the moment'
        }
      })
    }
  }

</script>

